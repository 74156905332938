import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotificationBannerMessageModel } from '@app/+authenticated/shared/notification-banner/notification-banner.component.model';
import { FeatureFlagPipe } from '@app/pipes/feature-flag.pipe';
import { KnowledgeBaseArticleLinkModule } from '@app/pipes/knowledge-base-article-link.module';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AnchorButtonComponent, ButtonComponent, IconButtonComponent, IconComponent } from '@sb/ui';

@Component({
  selector: 'notification-banner',
  templateUrl: './notification-banner.component.html',
  imports: [
    FeatureFlagPipe,
    TranslationParamsPipe,
    KnowledgeBaseArticleLinkModule,
    TranslateModule,
    IconComponent,
    RouterModule,
    NgIf,
    ButtonComponent,
    AnchorButtonComponent,
    IconButtonComponent,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBannerComponent {
  @Output()
  public closeHandler = new EventEmitter();

  @Input()
  public showSupportButton: boolean;
  @Input()
  public showFeatureBanner = false;
  @Input()
  public showCloseIcon = true;
  @Input()
  public notificationBannerMessage: NotificationBannerMessageModel;
}
