<div class="relative flex items-start rounded-base border border-solid border-grey-200 bg-white p-2.5">
  <div
    class="flex size-8 shrink-0 items-center justify-center rounded-full border border-solid border-grey-200 bg-white p-1 shadow-lg"
  >
    {{ notificationBannerMessage.emoji }}
  </div>
  <div class="ml-2.5 flex flex-1 flex-col">
    <span class="font-semibold text-word-mark-800">{{ notificationBannerMessage.title | translate }}</span>
    <span [innerHTML]="notificationBannerMessage.innerHTML | translate"></span>
    <div class="mt-2.5 flex flex-row gap-2">
      @if (showSupportButton) {
        <a sb-button [color]="'grey'" [routerLink]="['', { outlets: { modal: ['support'] } }]">
          {{ 'Support' | translate }}
        </a>
      }

      @if (notificationBannerMessage?.externalUrl) {
        <a
          sb-button
          [color]="'grey'"
          [attr.href]="notificationBannerMessage?.externalUrl | knowledgeBaseArticleLink"
          target="_blank"
        >
          {{ 'Learn more' | translate }}
        </a>
      }

      @if (notificationBannerMessage?.redirectLink) {
        <a sb-button [color]="'purple'" [routerLink]="notificationBannerMessage?.redirectLink">
          {{ (notificationBannerMessage.redirectLinkText | translate) || ('View changes' | translate) }}
          <sb-icon iconPositionEnd [name]="'arrow-right'" />
        </a>
      }
    </div>
  </div>

  @if (showCloseIcon) {
    <button sb-icon-button class="-mr-1 -mt-1" [color]="'transparent'" (click)="closeHandler.emit()">
      <sb-icon name="times" />
    </button>
  }
</div>
